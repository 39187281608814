import React from 'react'
import './Portfolio.css'
import Masonry from 'react-masonry-css'

import allProjects from "../../data/porjects.jsx"

const breakpointColumnsObj = {
  default: 3,
  1100: 2,
  700: 1
};

const Portfolio = () => {

  

  return (
    <section id="portfolio">
      <h5>My recent Works</h5>
      <h2>Portfolio</h2>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column">

        {
          allProjects.map((project) => {
            return <article key={project.id} className='portfolio__item'>
              <div className='portfolio_item-image'>
                <img src={project.image} alt="" />
              </div>
              <h3>{project.title}</h3>
              <p>{project.description}</p>
              <div id="more">
                <a href={project.link} className='btn' target="_blank" rel="noreferrer" >More</a>
              </div>

            </article>
          }
          )
        }


      </Masonry>

    </section>
  )
}

export default Portfolio