import React from 'react'
import './Header.css'
import CTA from './CTA'
import Shihab from '../../assets/shihab.png'
import HeaderSocial from './HeaderSocial'
import { TypeAnimation } from 'react-type-animation';


const Header = () => {
  return (
    <header>
      <div className="container header__container">
        <h5>Hello, I'm</h5>
        <TypeAnimation
          sequence={[
            'Shihab Sikder', // Types 'One'
            2000, // Waits 1s
          ]}
          wrapper="h1"
          speed={25}

          cursor={false}
          repeat={Infinity}
          style={{ fontSize: '3rem', fontFamily: 'sans-serif' }}
        />
        <h5 className="text-light">Backend Developer & Content Writer</h5>
        <CTA />
        <HeaderSocial />
        <div className='me'>
          <img src={Shihab} alt="Shihab Sikder" />
          <figcaption>It's me</figcaption>
        </div>
        <a href="#contact" className='scroll__down'>
          Scroll Down
        </a>
      </div>
    </header>
  )
}

export default Header