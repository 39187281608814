import React from 'react'
import './experience.css'
import { BsPatchCheckFill } from 'react-icons/bs'
const Experience = () => {
  return (
    <section id="experience">
      <h5>What experience I have</h5>
      <h2>My Experience</h2>
      <div className="container experience__container">
        <div className="experience_backend">
          <h3>FullStack Development</h3>
          <div className="experience__content">

            <article className='experience__details'>
              <BsPatchCheckFill />
              <div>
                <h4>JavaScript</h4>
              <small className='text-light'>Experienced</small>
              </div>
              
            </article>

            <article className='experience__details'>
              <BsPatchCheckFill />
              <div>
                <h4>NodeJS</h4>
              <small className='text-light'>Experienced</small>
              </div>
              
            </article>

            <article className='experience__details'>
              <BsPatchCheckFill />
              <div>
                <h4>Django</h4>
              <small className='text-light'>Intermediate</small>
              </div>
              
            </article>

            <article className='experience__details'>
              <BsPatchCheckFill />
              <div>
                <h4>MongoDB</h4>
              <small className='text-light'>Experienced</small>
              </div>
              
            </article>

            <article className='experience__details'>
              <BsPatchCheckFill />
              <div>
                <h4>Postgres</h4>
              <small className='text-light'>Experienced</small>
              </div>
              
            </article>

            <article className='experience__details'>
              <BsPatchCheckFill />
              <div>
                <h4>ReactJS</h4>
              <small className='text-light'>Intermediate</small>
              </div>
              
            </article>

            <article className='experience__details'>
              <BsPatchCheckFill />
              <div>
                <h4>TailwindCSS</h4>
              <small className='text-light'>Intermediate</small>
              </div>
              
            </article>

            <article className='experience__details'>
              <BsPatchCheckFill />
              <div>
                <h4>Flutter (Dart)</h4>
              <small className='text-light'>Intermediate</small>
              </div>
              
            </article>

          </div>
        </div>
        <div className="experience__security">
          <h3>Security and Automation Skills</h3>
          <div className="experience__content">

            <article className='experience__details'>
              <BsPatchCheckFill />
              <div>
                <h4>Data Forensics</h4>
                <small className='text-light'>Intermediate</small>
              </div>
            </article>

            <article className='experience__details'>
              <BsPatchCheckFill />
              <div>
                <h4>WAF</h4>
              <small className='text-light'>Intermediate</small>
              </div>
              
            </article>

            <article className='experience__details'>
              <BsPatchCheckFill />
              <div>
                <h4>Web Scrapping</h4>
              <small className='text-light'>Intermediate</small>
              </div>
              
            </article>

            <article className='experience__details'>
              <BsPatchCheckFill />
              <div>
                <h4>Selenium</h4>
              <small className='text-light'>Experienced</small>
              </div>
              
            </article>

            <article className='experience__details'>
              <BsPatchCheckFill />
              <div>
                <h4>Social Media Bot</h4>
              <small className='text-light'>Experienced</small>
              </div>
              
            </article>

            <article className='experience__details'>
              <BsPatchCheckFill />
              <div>
                <h4>XSS Framework</h4>
                <small className='text-light'>Intermediate</small>
              </div>
              
            </article>

          </div>
        </div>
      </div>
    </section>
  )
}

export default Experience