import React from 'react'
import './footer.css'
import {ImFacebook} from 'react-icons/im'
import {BsInstagram,BsLinkedin} from 'react-icons/bs'


const Footer = () => {
  return (
    <footer>
      <a href="#" className='footer__logo'>Tronics</a>
      <ul className='permalinks'>
        <li><a href="#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#experience">Experience</a></li>
        <li><a href="#service">Services</a></li>
        <li><a href="#portfolio">Portfolio</a></li>
      </ul>

      <div className="footer__socials">
        <a href="https://www.facebook.com/shihab.sikder.98"><ImFacebook/></a>
        <a href="https://www.linkedin.com/in/shihab-sikder/"><BsLinkedin/></a>
        <a href="https://instagram.com/shihab__sikder"><BsInstagram/></a>
      </div>

      <div className='footer__copyright'>
        <small>&copy; tronics. All rights reserved</small>
      </div>
    </footer>
  )
}

export default Footer