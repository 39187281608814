import React from 'react'
import './Service.css'
import { BiCheck } from 'react-icons/bi'
const Service = () => {
  return (
    <section id="services">
      <h5>What I offer</h5>
      <h2>Services</h2>

      <div className="container services_container">

        <article className="service">
          <div className="service_head">
            <h3>Data Mining and Automation</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheck id="icon"/>
              <p>Instagram Bot</p>
            </li>

            <li>
              <BiCheck id="icon"/>
              <p>Web scrapping</p>
            </li>

            <li>
              <BiCheck id="icon"/>
              <p>Data mining from cross-sites</p>
            </li>

            <li>
              <BiCheck id="icon"/>
              <p>Automations via API</p>
            </li>
          </ul>
        </article>

        <article className="service">
          <div className="service_head">
            <h3>Website Backend</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheck id="icon"/>
              <p>User authentication</p>
            </li>

            <li>
              <BiCheck id="icon"/>
              <p>User validation with JWT</p>
            </li>

            <li>
              <BiCheck id="icon"/>
              <p>Secure API endpoints</p>
            </li>

            <li>
              <BiCheck id="icon"/>
              <p>Custom Middlewares</p>
            </li>

            <li>
              <BiCheck id="icon"/>
              <p>Database connectons and complex queries</p>
            </li>

            <li>
              <BiCheck id="icon"/>
              <p>Schema design and data models</p>
            </li>


          </ul>
        </article>

        <article className="service">
          <div className="service_head">
            <h3>Content Writing</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheck id="icon"/>
              <p>Blogs related to programming, development and system design.</p>
            </li>

            <li>
              <BiCheck id="icon"/>
              <p>"How to" style contents.</p>
            </li>

            <li>
              <BiCheck id="icon"/>
              <p>Tutorial type articles.</p>
            </li>

          </ul>
        </article>
      </div>
      <div id="contract">
        <a href="https://www.upwork.com/workwith/shihabsikder98" className='btn btn-primary'>Create a contract</a>
      </div>
    </section>
  )
}

export default Service