import React, { useRef,useState } from 'react'
import './contact.css'
import emailjs from 'emailjs-com'
import { MdEmail } from 'react-icons/md'
import { RiMessengerLine, RiWhatsappLine } from 'react-icons/ri'
import { SiUpwork } from 'react-icons/si'

const Contact = () => {
  const [name,setName] = useState("");
  const [email,setEmail] = useState("");
  const [msg,setMsg] = useState("");

  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_kgpkye8', 'template_0yllqas', form.current, 'YWWFIGmyoOqZ3SbLH');
    setName("");
    setEmail("");
    setMsg("");
  };
  return (
    <section id="contact">
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className='container contact__container'>
        <div className="contact__options">
          <article className='contact__option'>
            <RiMessengerLine className='contact__icon' />
            <h4>Messenger</h4>
            <h5>Shihab Sikder</h5>
            <a href="https://m.me/shihab.sikder.98" target="_blank" rel="noreferrer">Send a message</a>
          </article>

          <article className='contact__option'>
            <RiWhatsappLine className='contact__icon' />
            <h4>WhatsApp</h4>
            <h5>+88 01915824234</h5>
            <a href="https://wa.me/8801915824234" target="_blank" rel="noreferrer">Send a message</a>
          </article>

          <article className='contact__option'>
            <SiUpwork className='contact__icon' />
            <h4>Upwork</h4>
            <h5>Shihab Sikder</h5>
            <a href="https://www.upwork.com/freelancers/~019af99e47c802907e" target="_blank" rel="noreferrer">Send a message</a>
          </article>

        </div>
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name='name' placeholder='Your full name' value={name} onChange={(e)=>setName(e.target.value)} required />
          <input type="email" name='email' placeholder='Enter your email' value={email} onChange={(e)=>setEmail(e.target.value)} required />
          <textarea name="message" rows="7" placeholder='Your message' value={msg} onChange={(e)=>setMsg(e.target.value)} required></textarea>
          <button type='submit' className='btn btn-primary'>Send Message</button>
        </form>
      </div>
    </section>
  )
}

export default Contact