import React from 'react'
import { RiAwardLine } from 'react-icons/ri'
import { FiUsers } from 'react-icons/fi'
import { VscFolderLibrary } from 'react-icons/vsc'
import './about.css'
import shihab_3 from '../../assets/shihab_cover.jpeg'


const About = () => {
  return (
    <service id="about">
      <h5 id="credit">Photo Credit <b><a href="https://instagram.com/salbin_khan" target="_blank" rel="noreferrer">Salbin Khan</a></b></h5>
      <h5>Get to Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">

          <div className="about__me-image">
            <img src={shihab_3} alt="About Shihab Sikder" />
          </div>

        </div>
        <div className="about__content">
          <div className="about__cards">

            <article className='about_card'>
              <RiAwardLine className='about__icon' />
              <h5>Experience</h5>
              <small>2+ Years Working</small>
            </article>

            <article className='about_card'>
              <FiUsers className='about__icon' />
              <h5>Clients</h5>
              <small>10+ Clients worldwide</small>
            </article>

            <article className='about_card'>
              <VscFolderLibrary className='about__icon' />
              <h5>Projects</h5>
              <small>14+ Completed projects</small>
            </article>
          </div>

          <p>
            Hello, I'm Shihab Sikder, majoring in Computer Science and Engineering (CSE) at Islamic University of Technology. Besides, my academics, I'm a <strong className='font_bold'> Top Rated <svg height={15} width={12}><polygon fill="#5aa2c6" points="6.98 0 0 0 0 9.21 6.98 14 13.95 9.21 13.95 0 6.98 0"></polygon></svg></strong> freelance programmer and content writer in Upwork. I love to learn. My currrent thesis is based on "Web Assembly". I like to take part in CTF competation. Most of my time spent on doing projects and watching tutorials.
          </p>

          <a href="#contact" className='btn btn-primary'>Contact Me</a>
        </div>
      </div>

    </service>
  )
}

export default About