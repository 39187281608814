import React from 'react'
import {BsLinkedin, BsFacebook, BsGithub} from 'react-icons/bs'
const HeaderSocial = () => {
  return (
    <div className='header__socials'>
        <a href="https://www.linkedin.com/in/shihab-sikder/" target="_blank" rel="noreferrer"><BsLinkedin/></a>
        <a href="https://github.com/arctronic" target="_blank" rel="noreferrer"><BsGithub/></a>
        <a href="https://www.facebook.com/shihab.sikder.98" target="_blank" rel="noreferrer"><BsFacebook/></a>
    </div>
  )
}

export default HeaderSocial