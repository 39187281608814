import pomoduo from "../assets/pomoDuo-showcase.png"
import abridge from "../assets/abridge-showcase.png"
import autoresuma from "../assets/autoresuma-showcase.png"
import fridgefoodies from "../assets/fridgefoodies-showcase.png"
import shareDear from "../assets/shareDear-showcase.PNG"
import lifeline from "../assets/lifeline-showcase.png"
import algosketch from "../assets/algosketch-showcase.png"
import cedit from "../assets/cedit-showcase.png"
import ants from "../assets/ants-showcase.png"
import food101 from "../assets/food101-showcase.PNG"
const allProjects = [
    {
        "id":0,
        "title": "Food Classification & Recipe",
        "link": "https://food101.tronics.dev",
        "description": "Using Efficientv2, the model has 92% accuracy over food101 dataset. It can detect recipe, provide you the nutrition and steps for the recipe.",
        "image": food101
    },
    {
        "id":1,
        "title": "Pomoduo",
        "link": "https://github.com/nandbyte/pomoduo",
        "description": "Shared Pomodoro Timer for Teams and Groups. Work both for single player and multiplayer mode.",
        "image": pomoduo
    },

    {
        "id":2,
        "title": "Abridge Chrome Extension",
        "link": "https://github.com/nandbyte/abridge-chrome-extension",
        "description": "Chrome extension that summarizes selected text using machine learning",
        "image": abridge
    },

    {
        "id":3,
        "title": "Autoresuma",
        "link": "https://github.com/nandbyte/autoresuma",
        "description": "Easily Maintain Your Computer Science Resume. Create your resume in a moment.",
        "image": autoresuma
    },

    {
        "id":4,
        "title": "Fridgefoodies",
        "link": "https://github.com/nandbyte/fridgefoodies",
        "description": "Search for Recipe from Ingredients Available in your Fridge. Share your recipe with steps. Search you preffered meal with specific callorie limit",
        "image": fridgefoodies
    },

    {
        "id":5,
        "title": "ShareDear",
        "link": "https://sharedear.tronics.dev/",
        "description": "Photo sharing social media. Share, pin and download original sized photo with different categories. Share thoughts on pictures.",
        "image": shareDear
    },

    {
        "id":6,
        "title": "LifeLine",
        "link": "https://github.com/nandbyte/lifeline",
        "description": "LifeLine is an Electronic Health Record application. It lets users save and preserve their health record and medical history. Doctors can validate users medical data through QR code validation. Search nearby blood donors",
        "image": lifeline
    },
    {
        "id":7,
        "title": "ANTS",
        "link": "https://antsbd.com/",
        "description": "I worked as a fullstack developer for ANTS. ANTS is a drone technology startup firm based in Bangladesh, founded in 2019. We are the country’s first and only company focused on commercial drone applications and services.",
        "image": ants
    },
    
    {
        "id":8,
        "title": "Algosketch",
        "link": "https://github.com/zedrex/algosketch",
        "description": "It's algorithm visualizer, created with C++ and SFML. This visualizes sorting algorithms(Insertion, Gnome, Selection, Bubble, etc) and graph algorithms(Floodfill, BFS and DFS)",
        "image": algosketch
    },

    {
        "id":9,
        "title": "Cedit",
        "link": "https://github.com/zedrex/cedit",
        "description": "A lightweight terminal-based basic text editor written in C.",
        "image": cedit
    },

]

export default allProjects;